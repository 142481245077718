import React, { useState } from 'react';
import './ContactForm.css';

const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [emailError, setEmailError] = useState('');
  const [submitMessage, setSubmitMessage] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError(''); // Reset error when typing
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address.');
      return;
    }

    try {
      const response = await fetch('http://localhost:5000/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, message }),
      });

      if (response.ok) {
        setSubmitMessage('Message sent successfully!');
        setName('');
        setEmail('');
        setMessage('');
      } else {
        setSubmitMessage('Failed to send message. Please try again later.');
      }
    } catch (error) {
      setSubmitMessage('An error occurred. Please try again later.');
    }
  };

  return (
    <section className="contact-form">
      <h2>Contact Us</h2>
      <form onSubmit={handleSubmit}>
        <input 
          type="text" 
          placeholder="Your Name" 
          value={name}
          onChange={(e) => setName(e.target.value)}
          required 
        />
        <input 
          type="email" 
          placeholder="Your Email" 
          value={email} 
          onChange={handleEmailChange} 
          required 
        />
        {emailError && <p style={{ color: 'red' }}>{emailError}</p>}
        <textarea 
          placeholder="Your Message" 
          rows="5" 
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
        ></textarea>
        <button type="submit">Send Message</button>
        {submitMessage && <p>{submitMessage}</p>}
      </form>
    </section>
  );
};

export default ContactForm;
