// src/components/Home.js
import React from 'react';
import './Home.css';
import Header from '../components/Header';
import Services from '../components/Services';
import Testimonials from '../components/Testimonials';
import ContactForm from '../components/ContactForm';

const Home = () => {
  return (
    <div>
      <Header />
      <Services />
      <Testimonials />
      <ContactForm />
    </div>
  );
};

export default Home;
