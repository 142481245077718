import React from 'react';
import { FaCloud, FaCode, FaLightbulb, FaNetworkWired } from 'react-icons/fa';
import { GrCompliance ,GrAndroid} from "react-icons/gr";
import { RxGlobe } from "react-icons/rx";

import './Services.css';

const Services = () => {
  return (
    <section id="services" className="section services">
      <div className="services-row">
        <div className="service">
          <FaLightbulb className="service-icon" />
          <h3>IT Strategy Consulting</h3>
          <p>Tailored IT Strategies.</p>
          <p>Expert Guidance.</p>
          <p>Technology Alignment.</p>
          <p>Future-Ready Solutions.</p>
        </div>
        <div className="service">
          <FaNetworkWired className="service-icon" />
          <h3>Network Management</h3>
          <p>Enterprise Network Management.</p>
          <p>Passive Networking.</p>
          <p>Wireless Connectivity.</p>
          <p>Network Audit.</p>
        </div>
        <div className="service">
          <GrCompliance className="service-icon" />
          <h3>Consulting and Compliance</h3>
          <p>Consultation on ISO 27001-2022 Lead Auditor.</p>
          <p>Facilitation and Support for Internal Audit.</p>
          <p>Analyzing Risk Assessment improvement.</p>
          <p>Vulnerability assessment and mitigation.</p>
        </div>
      </div>
      <div className="services-row">
        <div className="service">
          <RxGlobe className="service-icon" />
          <h3>Website Development</h3>
          <p>Customized Solutions.</p>
          <p>User-Centric Design.</p>
          <p>Cutting-Edge Technology.</p>
          <p>Collaborative Process.</p>
        </div>
        <div className="service">
          <GrAndroid className="service-icon" />
          <h3>Android Development</h3>
          <p>Custom Android Apps.</p>
          <p>User-Focused Design.</p>
          <p>Advanced Technology Integration.</p>
          <p>End-to-End Support.</p>
        </div>
      </div>
    </section>
  );
};

export default Services;
