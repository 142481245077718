import React from 'react';
import './About.css'; // Ensure this path is correct

const About = () => {
  return (
    <div className="about-page">
      <section className="about-intro">
        <h1>About Us</h1>
        <p>
            Welcome to Sevenite Consultancy, where innovation meets expertise. As a leading consulting firm, we specialize in delivering top-tier services across various domains, including IT Strategy Consulting, Network Management, Consulting and Compliance, Website Development, and Android Development. Our team of experts is committed to providing tailored solutions that not only meet but exceed your expectations.
        </p>
      </section>
      <section className="company-mission">
        <h2>Our Mission</h2>
        <p>
            At Sevenite Consultancy, our mission is to empower businesses with cutting-edge solutions that align technology with business strategy. We strive to be your trusted partner, guiding you through the complexities of the digital world with clarity and precision. Our goal is to ensure that your organization thrives in today’s fast-paced, competitive landscape.
        </p>
      </section>
      <section className="our-values">
        <h2>Our Core Values</h2>
        <div className="values-container">
          <div className="value-item">
            <h3>Integrity</h3>
            <p>We maintain the highest standards of honesty and ethical practices in every project we undertake.</p>
          </div>
          <div className="value-item">
            <h3>Innovation</h3>
            <p> Embracing creativity and the latest technology to deliver solutions that not only meet but surpass client expectations.</p>
          </div>
          <div className="value-item">
            <h3>Customer Focus</h3>
            <p>Your success is our priority. We work closely with you to provide personalized solutions that drive real results.</p>
          </div>
          <div className="value-item">
            <h3>Excellence</h3>
            <p> We strive for excellence in all aspects of our work, ensuring quality and impactful outcomes for every project.</p>
          </div>
        </div>
      </section>
      <section className="our-team">
        <h2>Meet Our Team</h2>
        <p>
          Our team is composed of highly skilled professionals with diverse expertise. Together, we are committed to providing exceptional consulting services and helping our clients achieve their strategic goals.
        </p>
        {/* <p>Stay tuned for more information about our dedicated team members and their roles.</p> */}
      </section>
    </div>
  );
};

export default About;
