// src/components/Testimonials.js
import React from 'react';
import './Testimonials.css';

const Testimonials = () => {
  return (
    <section className="testimonials">
      <h2>What Our Clients Say</h2>
      <div className="testimonial">
        <p>"Sevenite consultancy provided exceptional support in helping us achieve ISO 27001-2022 certification. Their expertise in risk assessment and vulnerability mitigation was invaluable. They guided us through the internal audit process with professionalism and clarity, ensuring that we met all compliance requirements seamlessly. We now have a stronger, more secure infrastructure thanks to their thorough and insightful consulting."</p>
        <span>- Harsh</span>
      </div>
      <div className="testimonial">
        <p>"Working with Sevenite consultancy has been a game-changer for our business. Their team took the time to understand our needs and delivered a website that exceeded our expectations. The process was smooth, the communication was clear, and the end result is a site that our customers love!"</p>
        <span>- Sumit</span>
      </div>
    </section>
  );
};

export default Testimonials;
