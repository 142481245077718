// src/components/Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

const Header = () => {
  return (
    <header className="header">
      <div className="overlay"></div>
      <div className="header-content">
        <h1>Leading IT Consultancy Services</h1>
        <p>We provide strategic IT solutions to help businesses succeed.</p>
        {/* <button to="/services" className="cta-button">Get Started</button> */}
        <Link to="/services" className="cta-button">Get Started</Link>
      </div>
    </header>
  );
};

export default Header;
